.container {
  background: white;
  margin: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  margin-left: 20%;
}

.tableContainer {
  overflow-y: auto;
  width: 100%;
}

.heading {
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1.3rem; /* Decreased by an additional 0.05 rem */
  color: rgb(52, 71, 103);
  font-weight: 500;
  text-align: left;
}

.tableStyle {
  border-collapse: collapse;
  width: 100%;
}

.tableStyle th,
.tableStyle td {
  padding: 0.3rem 0.6rem; /* Decreased padding further */
  text-align: left;
  color: rgb(131, 146, 171);
  font-size: 0.8rem; /* Decreased by an additional 0.05 rem */
  font-weight: 400;
}

.tableStyle th {
  height: 35px;
  font-size: 0.8rem; /* Decreased by an additional 0.05 rem */
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 999;
}

.searchbar {
  margin-bottom: 1%;
  padding: 1%;
  font-size: 0.7rem; /* Decreased by an additional 0.05 rem */
  border-radius: 5px;
  border: 2px solid #f0f0f0;
  width: 20%;
}

.searchbar:focus {
  outline: none;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .heading {
    font-size: 1.1rem; /* Decreased by an additional 0.05 rem */
  }

  .tableStyle th,
  .tableStyle td {
    font-size: 0.7rem; /* Decreased by an additional 0.05 rem */
    padding: 0.3rem 0.6rem; /* Decreased padding further */
  }

  .searchbar {
    font-size: 0.65rem; /* Decreased by an additional 0.05 rem */
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1rem; /* Decreased by an additional 0.05 rem */
  }

  .tableStyle th,
  .tableStyle td {
    font-size: 0.6rem; /* Decreased by an additional 0.05 rem */
    padding: 0.3rem 0.6rem; /* Decreased padding further */
  }

  .searchbar {
    font-size: 0.6rem; /* Decreased by an additional 0.05 rem */
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 0.8rem; /* Decreased by an additional 0.05 rem */
  }

  .tableStyle th,
  .tableStyle td {
    font-size: 0.5rem; /* Decreased by an additional 0.05 rem */
    padding: 0.2rem 0.5rem; /* Decreased padding further */
  }

  .searchbar {
    font-size: 0.5rem; /* Decreased by an additional 0.05 rem */
  }
}

/* New styles for the button */
.custom-button {
  font-size: 0.55rem; /* Decreased by an additional 0.05 rem */
  padding: 3px 5px; /* Adjusted padding */
  min-width: auto;
}

@media (max-width: 1200px) {
  .custom-button {
    font-size: 0.5rem; /* Decreased by an additional 0.05 rem */
    padding: 3px 5px; /* Adjusted padding */
  }
}

@media (max-width: 768px) {
  .custom-button {
    font-size: 0.4rem; /* Decreased by an additional 0.05 rem */
    padding: 3px 4px; /* Adjusted padding */
  }
}

@media (max-width: 480px) {
  .custom-button {
    font-size: 0.3rem; /* Decreased by an additional 0.05 rem */
    padding: 2px 3px; /* Adjusted padding */
  }
}
