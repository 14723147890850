.navBox {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white background */
  backdrop-filter: blur(10px) saturate(180%); /* Blur and saturate to enhance glass effect */
  -webkit-backdrop-filter: blur(10px) saturate(180%); /* For Safari support */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
  z-index: 1000; /* Ensure it stays on top */
}

.title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.contentBox {
  display: flex;
  gap: 20px;
  align-items: center;
}

.inputBox {
  position: relative;
}

.input {
  width: 270px;
  height: 35px;
  text-align: center;
  background-color: rgba(
    249,
    250,
    251,
    0.8
  ); /* Semi-transparent input background */
  padding: 2px 32px 2px 24px;
  border-radius: 20px;
  font-size: 0.9rem;
}
.admin {
  margin: 0;
  font-size: 16px;
}
.adminRole {
  margin: 0;
  font-size: 12px;
}
.admindropdown {
  width: 40%;
}
.countrydropdown {
  width: 35%;
}
.typographyContainer {
  text-align: center;
  font-weight: bold;
  color: #52546c;
  height: 60vh;
  padding: 50px;
  border-radius: 10px;
}
.typographyContent {
  margin-top: 1.3rem;
  text-align: center;
  font-weight: bold;
}
.typographyContent img {
  padding-top: 1.5rem;
}
.typographyContent small {
  display: block;
  padding-top: 1.5rem;
}
::-webkit-scrollbar {
  width: 0.2rem;
}
::-webkit-scrollbar-track {
  background-color: rgb(238, 242, 245);
}
::-webkit-scrollbar-thumb {
  background-color: #c9c2ac6e;
  border-radius: 10px;
}
@media (min-width: 1340px) and (max-width: 1400px) {
}
@media (min-width: 1020px) and (max-width: 1340px) {
  .title {
    font-size: 1.5rem;
  }
  .input {
    width: 150px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .admindropdown {
    display: none;
  }
  .countrydropdown {
    display: none;
  }
  .title {
    font-size: 1.5rem;
  }
}
