.container {
  background: white;
  margin: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  margin-left: 20%;
}

.tableContainer {
  overflow-y: auto;
  width: 100%;
}

.heading {
  padding-left: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: rgb(52, 71, 103);
  font-weight: 500;
}

.tableStyle {
  border-collapse: collapse;
  width: 100%;
}

.tableStyle th,
.tableStyle td {
  padding: 0.5rem 0.8rem;
  text-align: center;
  color: rgb(131, 146, 171);
  font-size: 1rem;
  font-weight: 400;
}

.tableStyle th {
  height: 40px;
  font-size: 1rem;
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 999;
}

.searchbar {
  margin-bottom: 1%;
  padding: 1%;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 2px solid #f0f0f0;
  width: 20%;
}

.searchbar:focus {
  outline: none;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .heading {
    font-size: 1.3rem;
  }

  .tableStyle th,
  .tableStyle td {
    font-size: 0.9rem; /* Adjusted font size for smaller screens */
    padding: 0.4rem 0.7rem; /* Adjusted padding for smaller screens */
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.2rem;
  }

  .tableStyle th,
  .tableStyle td {
    font-size: 0.8rem; /* Adjusted font size for even smaller screens */
    padding: 0.3rem 0.6rem; /* Adjusted padding for even smaller screens */
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1rem;
  }

  .tableStyle th,
  .tableStyle td {
    font-size: 0.7rem; /* Adjusted font size for mobile screens */
    padding: 0.2rem 0.5rem; /* Reduced padding for mobile screens */
  }
}

/* New styles for the button */
.custom-button {
  font-size: 0.75rem;
  padding: 4px 8px;
  min-width: auto;
}

@media (max-width: 1200px) {
  .custom-button {
    font-size: 0.7rem;
    padding: 4px 8px;
  }
}

@media (max-width: 768px) {
  .custom-button {
    font-size: 0.6rem;
    padding: 3px 6px;
  }
}

@media (max-width: 480px) {
  .custom-button {
    font-size: 0.5rem;
    padding: 2px 4px;
  }
}
