.box {
  display: flex;
  justify-content: space-evenly;
  /* margin-top: -10px; */
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
  /* gap: 0.55rem; */
  /* margin-left: 1.7%; */
  /* padding: 0.2rem; */
}
.innerbox {
  /* margin-top: 1.2%; */
  width: 18%;
  height: 25vh;
  min-width: 100px;
  /* margin: 1%; */
  /* margin-bottom: 1.7%; */
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  align-items: stretch;
  justify-content: space-between;
  border-radius: 1rem;
  transition: background-color 0.3s;
}

.active {
  background-color: #eac417;
}
.textContainer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}
.button {
  margin-right: 2rem;
  padding: 0.5rem 1.3rem;
  color: #0f3659;
  background-color: white;
  border: 1px solid #c3d3e2;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.container {
  background-color: white;
  box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(20, 20, 20, 0.05) 0rem 0.125rem 0.25rem -0.0625rem;
  /* width: 78%; */
  width: 100%;
  /* height: 42vh; */
  /* border: 2px solid green; */
  border-radius: 0.5rem;
  /* margin-left: 20%; */
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}
.heading {
  color: #05004e;
  font-weight: 600;
  font-size: 20px;
}
.subHead {
  font-weight: 400;
  /* font-size: 0.85rem; */
  font-size: 1rem;

  /* margin-left: 2.4rem;*/
  margin-top: 4px;
  color: #737791;
}
.price {
  color: rgb(52, 71, 103);
  font-size: 1rem;
  font-weight: 700;
  margin: 3px;
  margin-bottom: 0.625rem;
}

.logo {
  height: 2rem;
  width: 2rem;
  margin-bottom: 1rem;
}
.title {
  color: #425166;
  font-size: 0.95rem;
  width: 100%;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}
.subHeadContainer {
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1.5625rem;
}
.profit {
  margin-top: 3px;
  font-size: 0.8rem;
  color: rgb(28, 174, 203);
  margin-bottom: 0.5rem;
}
.link {
  text-decoration: none;
}

.innerbox.yellow {
  background-color: #ffe2e5; /* Yellow */
}

.innerbox.green {
  background-color: #dcfce7;
}

.innerbox.blue {
  background-color: #fff4de;
}

.innerbox.pink {
  background-color: #f3e8ff;
}

.innerbox.purple {
  background-color: #e8feff;
}
.outlet {
  width: 100%;
  height: calc(90vh - 310px);
  display: flex;
  gap: 10px;
}
.customtable,
.barchart {
  width: 100%;
  height: 55vh;
  background-color: white;
  box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(20, 20, 20, 0.05) 0rem 0.125rem 0.25rem -0.0625rem;
  border-radius: 1rem;
}
.customtable {
  overflow-y: scroll;
}
@media (min-width: 1020px) and (max-width: 1340px) {
  .heading {
    font-size: 1.1rem;
  }
  .innerbox {
    width: 18%;
    height: 40vh;
    min-width: 100px;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    align-items: stretch;
    justify-content: space-between;
    border-radius: 0.7rem;
  }
  .container {
    height: 38vh;
    border-radius: 10px;
    font-size: 0.8rem;
  }
  .price,
  .title,
  .profit,
  .logo {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    height: 400px;
    font-size: 0.9rem;
  }
  .innerbox {
    width: 30%;
    height: 130px;
    min-width: 100px;
    /* margin-bottom: 1%; */
  }
  .price,
  .title,
  .profit,
  .logo {
    margin: 0px;
    font-size: 0.7rem;
  }
  .heading {
    font-size: 1rem;
  }
  .subHead {
    font-size: 0.9rem;
  }
}
