.navRoute {
  width: 80%;
  margin-left: 21%;
}
@media (min-width: 1340px) and (max-width: 1400px) {
  .navRoute {
    width: 80%;
    margin-left: 21%;
  }
}
@media (min-width: 1020px) and (max-width: 1340px) {
  .navRoute {
    width: 70%;
    margin-left: 29%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navRoute {
    width: 60%;
    margin-left: 38%;
  }
}
